const axios = require("axios");
const slugify = require("slugify");

let BASE_URL;
if (process.env.NODE_ENV !== "production") {
    BASE_URL = process.env.BASE_URL || "http://localhost:8000";
} else {
    BASE_URL = process.env.BASE_URL || "https://www.actema.xyz";
}

export default {
    baseUrl: BASE_URL,
    ssr: true,

    /*
     ** Headers of the page
     */
    head: function() {
        let currentPath = "";
        if (this.route !== undefined && this.route.path !== undefined) {
            currentPath = this.route.path;
        } else if (this.$route !== undefined && this.$route !== undefined) {
            currentPath = this.$route.path;
        }

        return {
            title: "Actema - Prover with Interface",
            htmlAttrs: {
                lang: "en-EN",
            },
            meta: [{
                    charset: "utf-8",
                },
                {
                    name: "viewport",
                    content: "width=device-width, initial-scale=1, minimum-scale=1, user-scalable=0",
                },
                {
                    hid: "description",
                    name: "description",
                    content: "Actema is a interface-powered prover engine, designed to simplify the process of proving a theorem",
                },
            ],
            link: [{
                    rel: "icon",
                    type: "image/x-icon",
                    href: "/favicon.ico",
                },
                {
                    rel: "canonical",
                    href: this.baseUrl + currentPath,
                },
                {
                    // fontawesome, for icons
                    rel: "stylesheet",
                    integrity: "sha256-+N4/V/SbAFiW1MPBCXnfnP9QSN3+Keu+NlB+0ev/YKQ=",
                    href: "https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.11.2/css/all.min.css",
                    crossorigin: "anonymous",
                },
                {
                    // font for math
                    rel: "stylesheet",
                    href: "https://cdn.rawgit.com/dreampulse/computer-modern-web-font/master/fonts.css",
                    crossorigin: "anonymous",
                },
                {
                    // css for bootstrap - can be replaced with bootstrap-vue
                    rel: "stylesheet",
                    href: "https://cdnjs.cloudflare.com/ajax/libs/twitter-bootstrap/4.3.1/css/bootstrap.min.css",
                    crossorigin: "anonymous",
                },
            ],
            script: [{
                    // jquery
                    src: "https://code.jquery.com/jquery-3.5.1.slim.min.js",
                    integrity: "sha384-DfXdz2htPH0lsSSs5nCTpuj/zy4C+OGpamoFVy38MVBnE+IbbVYUew+OrCXaRkfj",
                    crossorigin: "anonymous",
                },
                {
                    // popper.js (bootstrap 4)
                    src: "https://cdn.jsdelivr.net/npm/popper.js@1.16.1/dist/umd/popper.min.js",
                    integrity: "sha384-9/reFTGAW83EW2RDu2S0VKaIzap3H66lZH81PoYlFhbGU+6BZp6G7niu735Sk7lN",
                    crossorigin: "anonymous",
                    body: true,
                },
                {
                    // bootstrap 4
                    src: "https://cdn.jsdelivr.net/npm/bootstrap@4.5.3/dist/js/bootstrap.min.js",
                    integrity: "sha384-w1Q4orYjBQndcko6MimVbzY0tgp4pWB4lZ7lr30WKz0vr/aWKhXdBNmNb5D92v7s",
                    crossorigin: "anonymous",
                    body: true,
                },
                {
                    // mathjax MML
                    src: "https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.6/MathJax.js?config=MML_HTMLorMML-full",
                    crossorigin: "anonymous",
                    body: true,
                },
                {
                    // mathjax TEX
                    src: "https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.6/MathJax.js?config=TEX_HTMLorTEX-full",
                    crossorigin: "anonymous",
                    body: true,
                },
                {
                    // the miniprover
                    src: "/js/miniprover.js",
                    body: true,
                },
            ],
        };
    },
    /*
     ** Customize the progress-bar color
     */
    loading: {
        color: "#fff",
    },

    /*
     ** Global CSS
     */
    //css: ["@/assets/scss/custom-bootstrap.scss"],

    env: {
        baseUrl: BASE_URL,
    },

    /*
     ** Plugins to load before mounting the App
     */
    plugins: [
        { src: '~/plugins/vuetouchevents', ssr: false },
    ],

    /*
     ** Nuxt.js modules
     */
    modules: [
        "bootstrap-vue/nuxt",
        "@nuxtjs/sitemap",
        "@nuxt/content",
        "@nuxtjs/axios",
    ],

    module: {
        rules: [{
            test: /\.s[ac]ss$/i,
            use: ["style-loader", "css-loader", "sass-loader"],
        }, ],
    },

    bootstrapVue: {
        bootstrapCSS: false,
        bootstrapVueCSS: false,
        componentPlugins: ["NavbarPlugin", "LayoutPlugin", "ButtonPlugin"],
        directivePlugins: ["VBTooltipPlugin"],
    },

    sitemap: {
        hostname: BASE_URL,
        gzip: true,
        defaults: {
            changefreq: "weekly",
            priority: 1,
            lastmod: new Date(),
        },
        routes: async () => {
            let sources = [BASE_URL + "/_content/docs", BASE_URL + "/_content/courses"];
            let arr = [];

            for (let s of sources) {
                const { data } = await axios.get(s);
                arr = arr.concat(data.map((article) => article.path));
            }

            return arr;
        },
    },

    // add support for latex in markdown files
    content: {
        liveEdit: false,
        markdown: {
            remarkPlugins: [
                'remark-math'
            ],
            rehypePlugins: [
                'rehype-katex'
            ]
        }
    },

    /*
     ** Build configuration
     */
    build: {
        /*
         ** Extend webpack config here
         */
        extend(config, ctx) {
            config.devtool = "#source-map";
        },

        /*
         * Remove babel warning
         */
        babel: {
            plugins: [
                ['@babel/plugin-proposal-private-methods', { loose: true }]
            ]
        }

        // analyze: {
        //   analyzerMode: 'static'
        // }
    },

    generate: {
        crawler: false,
    },

    hooks: {
        "content:file:beforeInsert": (document) => {
            if (document.extension === ".md") {
                // add some field to document here
            }
        },
    },
};