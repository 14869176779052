import config from "../nuxt.config.js";

var createHeader = function(attrs) {
    if (!attrs) attrs = {};
    let title = attrs.title || "Actema";
    let description = attrs.description || "Actema is an experimental GUI for interactive theorem proving.";
    let imageUrl = attrs.imageUrl || attrs.image || "";

    if (imageUrl.length > 0 && !imageUrl.startsWith(config.sitemap.hostname)) {
        imageUrl = config.sitemap.hostname + imageUrl; // add hostname to imageUrl
    }

    return {
        title: attrs.title,
        meta: [{
                hid: "description",
                name: "description",
                content: description,
            },
            {
                hid: "og:title",
                name: "og:title",
                content: title,
            },
            {
                hid: "og:description",
                name: "og:description",
                content: description,
            },
            {
                hid: "og:image",
                name: "og:image",
                content: imageUrl,
            },
            {
                hid: "twitter:title",
                name: "twitter:title",
                content: title,
            },
            {
                hid: "twitter:description",
                name: "twitter:description",
                content: description,
            },
            {
                hid: "twitter:image",
                name: "twitter:image",
                content: imageUrl,
            },
            {
                hid: "twitter:card",
                name: "twitter:card",
                content: "summary_large_image",
            },
        ],
    };
};

export default createHeader;